.resource-list {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.resource-list__item {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 1.2rem 1.6rem;
  min-height: 4.4rem;
  z-index: 2;

  @include media-breakpoint-up(sm) {
    padding: 1.2rem 2rem;
  }

  & + & {
    border-top: solid 1px $gray-300;
  }
}

.resource-list__item-owned {
  display: flex;
  align-self: flex-start;
  padding-right: 2rem;
}

.resource-list__item-content {
  flex: 1 1 auto;
  align-self: center;
  min-width: 0;
  max-width: 100%;
}

.resource-list__header {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0.4rem 1.6rem;
  border-bottom: solid 1px $gray-300;
  background-color: $gray-200;
  font-size: 1.2rem;

  @include media-breakpoint-up(sm) {
    padding: 0.4rem 2rem;
  }
}