//
// Headings
//

h1,
h2,
h3,
h4,
h5,
h6 {
  @include polaris-header;
}

//
// Horizontal rules
//

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: $hr-border-width solid $hr-border-color;
}


//
// Emphasis
//

small,
.small {
  font-size: $small-font-size;
  font-weight: $font-weight-normal;
}

mark,
.mark {
  padding: $mark-padding;
  background-color: $mark-bg;
}


//
// Lists
//

.list-unstyled {
  @include list-unstyled;
}

// Inline turns list items into inline-block
.list-inline {
  @include list-unstyled;
}
.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}


//
// Misc
//

// Builds on `abbr`
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

// Blockquotes
.blockquote {
  margin-bottom: $spacer;
  font-size: $blockquote-font-size;
}

.blockquote-footer {
  display: block;
  font-size: 80%; // back to default font-size
  color: $blockquote-small-color;

  &::before {
    content: "\2014 \00A0"; // em dash, nbsp
  }
}
