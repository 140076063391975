.empty-results {
  padding: 2rem 0;
  text-align: center;

  > .polaris-icon__svg {
    display: inline-block;
    width: 80px;
    height: 80px;
    margin: 0 auto 30px;
    fill: $gray-400;
  }

  .empty-results__title {
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.4rem;
    margin-bottom: 0.8rem;
  }
  
  .empty-results__subtitle {
    color: $gray-600;
    margin: 0 0 0.8rem;
  }
}