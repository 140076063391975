.page-actions {
  margin: 2rem -1.6rem;
  padding: 1.6rem;
  border-top: solid 1px rgba(0,0,0,0.1);

  .btn-group {
    display: flex;
    justify-content: space-between;
  }

  @include media-breakpoint-up(md) {
    margin-right: -2rem;
    margin-left:  -2rem;
    padding-right: 2rem;
    padding-left:  2rem;
  }
  @include media-breakpoint-up(lg) {
    margin-right: -3.2rem;
    margin-left:  -3.2rem;
    padding-right: 3.2rem;
    padding-left:  3.2rem;
  }

  &.page-actions--no-border {
    margin: 0 0 2rem;
    padding: 1.6rem 0;
    border-top: 0;

    @include media-breakpoint-up(md) {
      margin-right: 0;
      margin-left: 0;
      padding-right: 0;
      padding-left: 0;
    }
    @include media-breakpoint-up(lg) {
      margin-right: 0;
      margin-left: 0;
      padding-right: 0;
      padding-left: 0;
    }
  }
}