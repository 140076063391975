// Inline and block code styles
code,
kbd,
pre,
samp {
  font-family: $font-family-monospace;
}

// Inline code
code {
  padding: $code-padding-y $code-padding-x;
  font-size: $code-font-size;
  color: $code-color;
  background-color: $code-bg;
  @include border-radius($border-radius);

  // Streamline the style when inside anchors to avoid broken underline and more
  a > & {
    padding: 0;
    color: inherit;
    background-color: inherit;
  }
}

// User input typically entered via keyboard
kbd, .keyboard-key {
  display: inline-block;
  height: 2.4rem;
  margin: 0 .2rem 2px;
  padding: 0 .4rem;
  background-color: $white;
  box-shadow: 0 0 0 1px $gray-400, 0 2px 0 0 $white, 0 2px 0 1px #c4cdd5;
  border-radius: 3px;
  font-family: $font-family-base;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: $gray-600;
  text-align: center;
  min-width: 2.4rem;
  user-select: none;
}

// Blocks of code
pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: $code-font-size;
  color: $pre-color;

  // Account for some code outputs that place code tags in pre tags
  code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
    border-radius: 0;
  }
}

// Enable scrollable blocks of code
.pre-scrollable {
  max-height: $pre-scrollable-max-height;
  overflow-y: scroll;
}
