.page-header {
  width: 100%;
  margin: 2.4rem 0 1.6rem;
}

.page-header__navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > .page-header__back {
    padding-right: 0;
    padding-left: 0;
  }

  > .pagination {
    margin: 0;
  }
}

.page-header__content {
}

.page-header__actions {
  > .btn:first-child {
    padding-left: 0;
  }
}